var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap justify-space-between packaging"},[(_vm.getByClass('dimensioni_scaffale'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v("Dimensioni prodotto")]),(_vm.getByClass('width'))?_c('div',[_c('span',[_vm._v(_vm._s(_vm.getByClass("width").title))]),(_vm.getByClass('width').paths && _vm.getByClass('width').paths[0])?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("width").paths[0].content))]):_vm._e(),(_vm.getByClass('width').paths && _vm.getByClass('width').paths[1])?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("width").paths[1].content))]):_vm._e()]):_vm._e(),(_vm.getByClass('height'))?_c('div',[_c('span',[_vm._v(_vm._s(_vm.getByClass("height").title))]),(_vm.getByClass('height').paths && _vm.getByClass('height').paths[0])?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("height").paths[0].content))]):_vm._e(),(_vm.getByClass('height').paths && _vm.getByClass('height').paths[1])?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("height").paths[1].content))]):_vm._e()]):_vm._e(),(_vm.getByClass('depth'))?_c('div',[_c('span',[_vm._v(_vm._s(_vm.getByClass("depth").title))]),(_vm.getByClass('depth').paths && _vm.getByClass('depth').paths[0])?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("depth").paths[0].content))]):_vm._e(),(_vm.getByClass('depth').paths && _vm.getByClass('depth').paths[1])?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("depth").paths[1].content))]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.getByClass('imballaggio'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("imballaggio").title))]),(
        _vm.getByClass('imballaggio').paths && _vm.getByClass('imballaggio').paths[0]
      )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.getByClass("imballaggio").paths[0].content))])]):_vm._e()]):_vm._e(),(_vm.getByClass('pack_type'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("pack_type").title))]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.handleImballaggio()))])])]):_vm._e(),(_vm.getByClass('peso_lordo'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("peso_lordo").title))]),_c('div',[(
          _vm.getByClass('peso_lordo').paths && _vm.getByClass('peso_lordo').paths[0]
        )?_c('span',[_vm._v(_vm._s(_vm.getByClass("peso_lordo").paths[0].content))]):_vm._e(),(
          _vm.getByClass('peso_lordo').paths && _vm.getByClass('peso_lordo').paths[1]
        )?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("peso_lordo").paths[1].content))]):_vm._e()])]):_vm._e(),(_vm.getByClass('contenuto_netto'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("contenuto_netto").title))]),(
        _vm.getByClass('contenuto_netto').paths &&
          _vm.getByClass('contenuto_netto').paths[0]
      )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.handleNetContent(_vm.getByClass("contenuto_netto").paths[0].content)))])]):_vm._e()]):_vm._e(),(_vm.getByClass('drained_weight'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("drained_weight").title))]),_c('div',[(
          _vm.getByClass('drained_weight').paths &&
            _vm.getByClass('drained_weight').paths[0]
        )?_c('span',[_vm._v(_vm._s(_vm.getByClass("drained_weight").paths[0].content))]):_vm._e(),(
          _vm.getByClass('drained_weight').paths &&
            _vm.getByClass('drained_weight').paths[1]
        )?_c('span',[_vm._v(" "+_vm._s(_vm.getByClass("drained_weight").paths[1].content))]):_vm._e()])]):_vm._e(),(_vm.getByClass('packaging_material'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("packaging_material").title))]),(
        _vm.getByClass('packaging_material').paths &&
          _vm.getByClass('packaging_material').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("packaging_material").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('recycling_info'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("recycling_info").title))]),_c('div',[_vm._v(" "+_vm._s(_vm.handleInfRaccolta(_vm.getByClass("recycling_info").paths[0].content))+" ")])]):_vm._e(),(_vm.getByClass('consumer_recycling_instructions'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("consumer_recycling_instructions").title))]),(
        _vm.getByClass('consumer_recycling_instructions').paths &&
          _vm.getByClass('consumer_recycling_instructions').paths[0]
      )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getByClass('consumer_recycling_instructions').paths[0].content)}}):_vm._e()]):_vm._e(),(_vm.getByClass('unit_type'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("unit_type").title))]),(_vm.getByClass('unit_type').paths && _vm.getByClass('unit_type').paths[0])?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("unit_type").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('lingue_sull_imballaggio'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("lingue_sull_imballaggio").title))]),(
        _vm.getByClass('lingue_sull_imballaggio').paths &&
          _vm.getByClass('lingue_sull_imballaggio').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("lingue_sull_imballaggio").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('a_description'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("a_description").title))]),(
        _vm.getByClass('a_description').paths &&
          _vm.getByClass('a_description').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("a_description").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('single_use_plastics'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("single_use_plastics").title))]),(
        _vm.getByClass('single_use_plastics').paths &&
          _vm.getByClass('single_use_plastics').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("single_use_plastics").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('is_returnable'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("is_returnable").title))]),(
        _vm.getByClass('is_returnable').paths &&
          _vm.getByClass('is_returnable').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("is_returnable").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('is_recyclable'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("is_recyclable").title))]),(
        _vm.getByClass('is_recyclable').paths &&
          _vm.getByClass('is_recyclable').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("is_recyclable").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('packagingFunctionCode'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("packagingFunctionCode").title))]),(
        _vm.getByClass('packagingFunctionCode').paths &&
          _vm.getByClass('packagingFunctionCode').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("packagingFunctionCode").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('packagingFeatureCode'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("packagingFeatureCode").title))]),(
        _vm.getByClass('packagingFeatureCode').paths &&
          _vm.getByClass('packagingFeatureCode').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("packagingFeatureCode").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('securityTagLocationCode'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("securityTagLocationCode").title))]),(
        _vm.getByClass('securityTagLocationCode').paths &&
          _vm.getByClass('securityTagLocationCode').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("securityTagLocationCode").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('securityTagTypeCode'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("securityTagTypeCode").title))]),(
        _vm.getByClass('securityTagTypeCode').paths &&
          _vm.getByClass('securityTagTypeCode').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("securityTagTypeCode").paths[0].content)+" ")]):_vm._e()]):_vm._e(),(_vm.getByClass('packagingSustainabilityFeatureCode'))?_c('div',{staticClass:"w-45 mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.getByClass("packagingSustainabilityFeatureCode").title))]),(
        _vm.getByClass('packagingSustainabilityFeatureCode').paths &&
          _vm.getByClass('packagingSustainabilityFeatureCode').paths[0]
      )?_c('div',[_vm._v(" "+_vm._s(_vm.getByClass("packagingSustainabilityFeatureCode").paths[0].content)+" ")]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }